/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
@media (max-width: 1400px) {
  .icon-box {
    width: 2.5vw;
    height: 2.5vw;

    /* top:5% */
  }
  .icon-box span {
    color: #fff;
    font-size: 1.5vw;

    position: relative;
  }
  .aside-footer {
    padding-top: 100px;
  }
}

/* @media (max-width: 992px) {
  .aside-footer {
display: none;
  
  }
  .label{
    display: none;
  }
  .layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-item-selected
  .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-item-selected
  .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active {
  background-color:transparent;
  align-self: center;
  font-weight: 600;
  width: 100px;

  position: relative;
}
.layout-dashboard .ant-menu {
  right:15px;
  position: relative;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu {
  background-color:transparent;
  
  font-weight: 600;
  width: 100px;

  position: relative;
}

  .brand {
    width: 65px;
    bottom: 15px;
    height: 10px;
    right: 27.5px;
    position: relative;
  }
} */
@media (min-width: 100px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }

  .layout-dashboard .ant-layout {
    /* width: auto; */
    /* flex-shrink: 1; */
    margin-left: 12.5vw;

    align-self: center;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  /* .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  } */
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}

.ant-modal-close-x svg {
  fill: #c04848; /* Set your desired color here */
}

.ant-select .ant-select-arrow {
  color: #c04848;
}

/* Custom styles for Ant Design Pagination */
.ant-pagination .ant-pagination-item-active {
  background-image: linear-gradient(0deg, #c04848 0%, white 20%) !important;
  color: white !important; /* Optional: Change text color for active item */
}

.ant-tabs-ink-bar {
  /* height: 5px; */
  background: #c04848 !important;
  background-color: transparent;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  background-color: #c04848 !important; /* Change highlight color to red */
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  font-size: .75vw;
  margin: 5px;
}
