
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');


* {
	box-sizing: border-box;
}

body {
	background: #FFFFFF;
	/* font-family: 'Montserrat', sans-serif; */

	height: 100vh;
}


.ghost {
	background-color: transparent;
	padding: 10px 20px;
	border-style: solid;
	border-radius: 20px;
	border-width: 1px;
	border-color: #FFFFFF;
	color:#FFFFFF;
	transition: transform 80ms ease-in;
	/* text-transform: uppercase; */
}

.sign-in-button {
	background-image: linear-gradient(45deg, #C04848 0%, #480048 100%);
	padding: 10px 20px;
	border-style: solid;
	border-radius: 10px;
	border-width: 0px;
	border-color: #FFFFFF;
	color:#FFFFFF;
	transition: transform 80ms ease-in;
	/* text-transform: uppercase; */
}

.sign-in-button:hover{
	background-image: linear-gradient(45deg, #C04848 0%,  #480048 100%);;
	background-color: #FFFFFF;

	box-shadow: none;
	border-width: 0px;
	border-color: #FFFFFF;
}

.ghost:hover{
	background-color: #FFFFFF;
	color:#4f4bcb;
	box-shadow: none;
    border:1px solid #c9d9e9;
}

form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}
video {
  height: 105%;
  object-fit: cover;
  position: absolute;
  width: 100%;
  z-index: 0;
  left: 0;
  top: 0;
}

.form-container {
	position: fixed;
	top: 0;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: all 0.6s ease-in-out;
}

.signup-grid{
	display:grid;
	grid-template-columns: 1fr 1fr 2fr 2fr .3fr;
	grid-template-rows: .5fr 1fr;

	/* background-image: url('../assets/6517.jpg'); */
	
  }

.signup-form{
	display: flex;
	grid-column: 4;
	grid-row:1;
	/* font-family: 'Montserrat'; */
	text-align: center;
	max-width: 100%;
	top: 0;
	height: 100%;
	justify-content: space-evenly;
	background-color: #FFFFFF;
	box-shadow: 0 0px 1px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	padding-top: 5%;
	padding-bottom: 10%;
	z-index: 1;
}

.signup-pic{
	grid-column: 1;
	grid-row:1;
	size: 1rem;
	position:inherit;
	max-width: 400%;
	z-index: 0;
}

.form-fields{
    width: 100%;
	padding: 2%;
	margin: 5px;
	align-items: center;
	/* margin-top: 150px; */
	flex: 1;
	flex-direction: column;
	border-bottom-width: 20px;
	border: 'purple';
}

.reset-pass-input{
    /* max-width: 40%; */
	border-radius: 10px;
	/* padding: 2%; */
	/* background-color: white; */
	border-bottom-color: #4f4bcb;
	border-bottom-width: 15px;
	margin-top: 15px;
}

.sign-in-container {
	left: 0;
	width: 50%;
    height:100%;
	top: 30%;
	/* z-index: 1; */
    box-shadow: 0 0px 0px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
}


#signIn{
	color: #4f4bcb;
	font-weight: bold;
}

.overlay-container {
	/* background-image: linear-gradient(45deg, #000000 0%, #353939 100%); */
		position: fixed;
	top: 0;

	width: 50%;
	height: 100%;
	overflow:hidden;
	transition: transform 0.6s ease-in-out;

}

.overlay {
	/* background-image: linear-gradient(45deg, #1d34c2 0%, #d12675 100%); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	right: -100%;
	height: 120%;
	width: 200%;

}



.overlay-panel {
	position: absolute;
	/* display: flex; */
	align-items: center;
	justify-content: center;
	flex-direction: column;

	text-align: center;

	height: 80%;
	/* width: 50%; */
	top: 12.5%
}




.overlay-right {
	
	left: 0;
}



.social-container {
	margin: 10px 0;
}

.social-container a {
	border: 1px solid #565656;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

footer {
    background-color: #ffffff;
    font-size: 14px;
    bottom: 0;
    position:fixed;
    left: 0;
    right: 0;
    text-align: center;
	justify-items: center;
    z-index: 999;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 0px 0px rgba(0,0,0,0.22);
  
}

.footer-logo {
	max-width: 30%;
	color:#FFFFFF;
	align-self: center;
}


