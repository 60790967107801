.workout {
  width: 30vw;
  font-size: 1vw;
  border-width: 0;
  /* box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.25); */
  background-color: #262828; /* Set your desired background color */
  color: #333; /* Set your desired text color */
  /* Add more styles as needed */
}

.react-calendar__month-view__days__day {
  /* Common styles for both dark and light themes */
  font-size: .6vw;

  /* padding: 8px; */
}

/* Styles for days with workouts */
.react-calendar__month-view__days__day.workout-day {
  /* Additional styles for days with workouts */
  font-weight: bold;
}

/* Dark theme styles */
.dark-theme {
  background-color: #262828 !important; /* Set your dark background color */
  color: #fff !important; /* Set your dark text color */
}

/* Light theme styles */
.light-theme {
  background-color: #fff !important; /* Set your light background color */
  color: #333 !important; /* Set your light text color */
}

.workout-day {
  background-color: #c04848 !important;
  border-radius: 15%;
}

.react-calendar {
  border-radius: 5% !important; /* Ensures the calendar has no border radius */
}

/* Remove border radius on the tiles */
.react-calendar__tile {
  border-radius: 15% !important; /* Ensures the day tiles have no border radius */
}

.workout-day-completed {
  background-color: #67aee6 !important;
  color: white !important;
  border-radius: 15%;
}

/* Styles for the header with month and arrows */
.react-calendar__navigation {
  background-color: #000;
  border-radius: 10px; /* Set your header background color */
}

.react-calendar__navigation button {
  color: #fff; /* Set the arrow color */
}

.react-calendar__navigation button:hover {
  background-color: transparent !important; /* Remove hover effect for changing the background color */
}

/* Styles for days with workouts */
.react-calendar__month-view__days__day.workout-day {
  /* Additional styles for days with workouts */
  font-weight: bold;
}

/* Styles for the selected date */
.react-calendar__tile--active {
  background-color: #000 !important; /* Green */
  color: white !important;
  border-radius: 15%;
}

/* Styles for days in dark theme on hover */
.react-calendar.dark-theme .react-calendar__month-view__days__day:hover {
  color: #000; /* Set the day text color to black on hover */
}

.react-calendar {
  border-radius: 50px; /* Set your desired border-radius for the calendar */
}

#date {
  color: white;
}

.custom-error-style .ant-form-item-explain {
  font-size: 0.55vw !important;
}

.react-calendar__tile--active {
  background-color: #c04848 !important; /* Blue background */
  color: #fff !important; /* White text */
  border-radius: 5%;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #c04848 !important; /* Lighter blue on hover/focus */
}

.react-calendar__tile--active.dark-theme {
  background-color: #c04848 !important; /* Darker blue for dark theme */
}

.selected-day {
  background-color: #c04848 !important; /* Light green highlight */
  border-radius: 15%; /* Circular highlight */
  color: #fff !important; /* Text color */
  font-weight: bold; /* Stand out */
  border-radius: 5%;
}
